import React, { useRef, useState, useEffect } from 'react';
import '../assets/styles/Contact.scss';
// import emailjs from '@emailjs/browser';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import TextField from '@mui/material/TextField';

function Contact() {

  useEffect(() => {
    document.title = "Matt Marinelli | Contact";
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);



  return (
    <div>
      <div className="header-image">
        <img src="/banner.jpg" alt="Asset by Oziel Gómez" />
      </div>
      <div className="items-container">

          <h1>Contact Information</h1>
          <p>Please feel free to reach out to me via email or LinkedIn!</p>
          <p>
          email: mmarinelli1999@gmail.com <br/>
          linkedin: mattjmarinelli
          </p>

      </div>
    </div>
  );
}

export default Contact;