import React, { useEffect } from "react";
import { CodeBlock, monokai } from 'react-code-blocks';
import '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReact, faDocker, faPython } from '@fortawesome/free-brands-svg-icons';
import Chip from '@mui/material/Chip';
import '../assets/styles/Home.scss';
import {faCuttlefish} from "@fortawesome/free-brands-svg-icons/faCuttlefish";
import {faCode} from "@fortawesome/free-solid-svg-icons";
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const labelsFirst = [
    "Python",
    "R",
    "SQL",
    "C++",
];

const labelsSecond = [
    "Pandas",
    "PyTorch",
    "Scikit-learn",
    "Matplotlib",
    "NumPy",
];

const labelsThird = [
  "Git",
  "Linux",
  "Windows",
  "CMake",
  "PyTest",
];

function Footer() {
  return (
    <footer>
      <div>
        <a href="https://www.linkedin.com/in/mattjmarinelli/" target="_blank" rel="noreferrer"><LinkedInIcon/></a>
        <a href="https://github.com/mjm1999" target="_blank" rel="noreferrer"><GitHubIcon/></a>
      </div>

    </footer>
  );
}

function Home() {

  useEffect(() => {
    document.title = "Matt Marinelli Home";
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  return (
  <div>
    <div className="container">
      <div className="about-section">
        <div className="content">
          <h1>Welcome to my website 👋</h1>
          <p>
            Before we get started, please let me introduce myself:<br/>
          </p>

          <div className="desktop-codeblock">
            <CodeBlock
              text={
    `class Me(): 
   def __init__(self):
       self.firstName = 'Matt'   
       self.lastName = 'Marinelli'
       self.role = 'Software Developer'
       self.city = 'Huntsville, AL'
            
   def __str__(self):
       return f"Hi! I'm {self.firstName} {self.lastName}," + 
              f"a {self.role} based in {self.city}!"  
           
print(Me())`
  // `const person = {
  //   firstName: 'Matt',
  //   lastName: 'Marinelli',
  //   role: 'Software Developer',
  //   city: 'Huntsville, AL',
  //   fullName: function () {
  //     return this.firstName + ' ' + this.lastName;
  //   }
  // };
  //
  // function printIntro(obj) {
  //   console.log("Hi! I'm " + obj.fullName()
  //     + ", a " + obj.role + " based in " + obj.city);
  // };
  // printIntro(person);`
                }
              language={'python'}
              showLineNumbers={true}
              theme={monokai}
            />
          </div>

          <div className="mobile-responsive-codeblock">
            <CodeBlock
              text={
  `const person = {
    firstName: 'Matt',
    lastName: 'Marinelli',
    role: 'Software Developer',
    city: 'Huntsville, AL',
    fullName: function () {
      return this.firstName 
        + ' ' + this.lastName;
    }
  };
  
  function printIntro(obj) {
    console.log("Hi! I'm " 
      + obj.fullName() 
      + ", a " + obj.role 
      + " based in " 
      + obj.city);
  };
  printIntro(person);`
                }
              language={'javascript'}
              showLineNumbers={false}
              theme={monokai}
            />
          </div>
        </div>
        {/*<div className="image-wrapper">*/}
        {/*  <img src="https://my-aws-assets.s3.us-west-2.amazonaws.com/portfolio-img/home-profile.jpeg" alt="Asset by Oziel Gómez" />*/}
        {/*</div>*/}
      </div>
    </div>

    <div className="container" id="skills">
      <div className="skills-container">
        <h1>Skills</h1>
        <div className="skills-grid">
          <div className="skill">
            <FontAwesomeIcon icon={faCuttlefish} size="3x"/>
            <h3>Languages</h3>
            <p>
              Through academic, professional, and personal experience, I have developed a strong foundation in
                programming languages. I am proficient in Python, R, SQL, and C++.
            </p>
            <p className="flex-chips">Tech stack:
              {labelsFirst.map((label, index) => (
                  <Chip key={index} className='chip' label={label}/>
              ))}
            </p>
          </div>

          <div className="skill">
            <FontAwesomeIcon icon={faPython} size="3x"/>
            <h3>GenAI & ML</h3>
            <p>
              Pursuing Machine Learning through Georgia Tech and personal projects, I have experience with libraries
                and frameworks such as PyTorch, Pandas, and Scikit-learn.
            </p>
            <p className="flex-chips">Tech stack:
              {labelsSecond.map((label, index) => (
                  <Chip key={index} className='chip' label={label}/>
              ))}
            </p>
          </div>
          <div className="skill">
            <FontAwesomeIcon icon={faCode} size="3x"/>
            <h3>Software Architecture</h3>
            <p>
              My professional experience has allowed me to develop a strong understanding of software architecture
                 and how to best design and implement software systems.
            </p>
            <p className="flex-chips">Tech stack:
              {labelsThird.map((label, index) => (
                  <Chip key={index} className='chip' label={label}/>
              ))}
            </p>
          </div>

        </div>
      </div>
    </div>

    <div className="projects-container" id="project">
      <h1>Projects</h1>
      <div className="projects-grid">
        <div className="project">
          <a href="/RLTrader" rel="noreferrer"><img
              src="/project1.png" className="zoom"
              alt="thumbnail" width="90%" height="60%"/></a>
          <a href="/RLTrader" target="_blank" rel="noreferrer"><h2>RL+Optimization Trader</h2></a>
          <p>Machine learning trading algorithm that leverages technical indicators to make its decisions. Developed as a Georgia Tech project.</p>
        </div>

      </div>
    </div>
  </div>

  );
}

export default Home;