import React from 'react';

function FileDropdown({ options, handleFileChange }) { // Receive the options and handleFileChange as props
  return (
    <select className="dropdown-menu" onChange={handleFileChange}>
      {options}
    </select>
  );
}

export default FileDropdown;