import React, {useEffect} from "react";
import '../assets/styles/Resume.scss'

function Resume() {

  useEffect(() => {
    document.title = "Matt Marinelli | Resume";
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  return (
    <div>
      <div className="header-image">
        <img src="/banner.jpg" alt="Asset by Oziel Gómez" />
      </div>
      <div className="items-container">
        <div className="align-items-center">

        </div>
        <div className="pdf-container">
          <embed src="Matthew_Marinelli_Resume.pdf" type="application/pdf" width="100%" height="600px" />
        </div>
      </div>
    </div>
  );
}

export default Resume;