import React, {useEffect} from "react";
import '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBriefcase, faBuildingColumns} from '@fortawesome/free-solid-svg-icons';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import '../assets/styles/About.scss'

function About() {

  useEffect(() => {
    document.title = "Matt Marinelli | About";
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  return (
    <div>
      <div className="header-image">
        <img src="/banner.jpg" alt="Asset by Oziel Gómez" />
      </div>
      <div className="items-container">
        <div className="align-items-center">
          <h1>Brief Summary</h1>
          <p>
            Matt Marinelli is a software developer at Parsons Corporation, bringing skill and expertise to
            the software architecture and development team. He has a Bachelor of Science in Computer Engineering
            from Clemson University where he worked on an autonomous vehicle project as a research assistant
            and began his career in a software engineering internship at Enterprise Engineering Services. He is
            actively pursuing a Master of Science in Computer Science at Georgia Institute of Technology with
            a specialization in Machine Learning and hones the skills he learns in passion projects.
          </p>
        </div>
        <h1>Experience Timeline</h1>
        <VerticalTimeline>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2024 - present"
            iconStyle={{ background: '#FFD300', color: 'rgb(39, 40, 34)' }}
            icon={<FontAwesomeIcon icon={faBuildingColumns} />}
          >
            <h3 className="vertical-timeline-element-title">Georgia Institute of Technology M.S.</h3>
            <h4 className="vertical-timeline-element-subtitle">Atlanta, GA</h4>
            <p>
              Machine Learning, Data Science, Software Development
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: 'white', color: 'rgb(39, 40, 34)' }}
            contentArrowStyle={{ borderRight: '7px solid  white' }}
            date="2023 - present"
            iconStyle={{ background: '#FFD300', color: 'rgb(39, 40, 34)' }}
            icon={<FontAwesomeIcon icon={faBriefcase} />}
          >
            <h3 className="vertical-timeline-element-title">Software Developer</h3>
            <h4 className="vertical-timeline-element-subtitle">Huntsville, AL</h4>
            <p>
              Software Architecture, API Development, Agile Development
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2018 - 2022"
            iconStyle={{ background: '#FFD300', color: 'rgb(39, 40, 34)' }}
            icon={<FontAwesomeIcon icon={faBuildingColumns} />}
          >
            <h3 className="vertical-timeline-element-title">Clemson University B.S.</h3>
            <h4 className="vertical-timeline-element-subtitle">Clemson, SC</h4>
            <p>
              Low Level Programming, GenAI, Computer Vision
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2021 - 2021"
            iconStyle={{ background: '#FFD300', color: 'rgb(39, 40, 34)' }}
            icon={<FontAwesomeIcon icon={faBriefcase} />}
          >
            <h3 className="vertical-timeline-element-title">Undergraduate Research Assistant</h3>
            <h4 className="vertical-timeline-element-subtitle">Clemson, SC</h4>
            <p>
              ROS, Network Communication, Autonomous Vehicles
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2019 - 2021"
            iconStyle={{ background: '#FFD300', color: 'rgb(39, 40, 34)' }}
            icon={<FontAwesomeIcon icon={faBriefcase} />}
          >
            <h3 className="vertical-timeline-element-title">Computer Engineering Intern</h3>
            <h4 className="vertical-timeline-element-subtitle">Marlton, NJ</h4>
            <p>
              Application Development, Unit Testing, Software Design
            </p>
          </VerticalTimelineElement>
        </VerticalTimeline>
      </div>
    </div>
  );
}

export default About;