import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import {
  Navigation,
  Footer,
  Home,
  About,
  Contact,
    Resume,
    FileDropdown,
    RLTrader,
} from "./components";
import './index.scss';

ReactDOM.render(
  <Router>
    <Navigation />
    <Routes>
      <Route path="" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/resume" element={<Resume />} />
        <Route path="/rltrader" element={<RLTrader />} />
        <Route path="/api/dropdownmenu" element={<FileDropdown />} />
    </Routes>

  </Router>,

  document.getElementById("root")
);

serviceWorker.unregister();