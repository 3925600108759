import React, {useEffect, useState} from "react";
import Papa from 'papaparse';
import '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase } from '@fortawesome/free-solid-svg-icons';
import Switch from '@material-ui/core/Switch';

import TextField from '@material-ui/core/TextField';
import FileDropdown from './api/dropdownmenu';
import '../assets/styles/RLTrader.scss'
import {BeatLoader} from "react-spinners";


function CustomSwitch({ checked, onChange }) {
  return (
    <label className="custom-switch">
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
      />
      <span>{checked ? "" : ""}</span>
    </label>
  );
}

function RLTrader() {

    useEffect(() => {
    document.title = "Matt Marinelli | Contact";
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  const [dates, setDates] = useState({
    date1: "2002-01-01",
    date2: "2004-12-31",
    date3: "2005-01-01",
    date4: "2007-12-31",
  });

  const [selectedFile, setSelectedFile] = useState("A"); // New state for the selected file
    const [options, setOptions] = useState([]); // New state for the dropdown options
  const [error, setError] = useState("");
  const [inSampleImageUrl, setInSampleImageUrl] = useState(null); // New state variable
  const [outSampleImageUrl, setOutSampleImageUrl] = useState(null); // New state variable
    const [inSampleCsvData, setInSampleCsvData] = useState([]);
    const [outSampleCsvData, setOutSampleCsvData] = useState([]);
  const [optimize, setOptimize] = useState("false");
  const [isLoading, setIsLoading] = useState(false); // Add this line
    const [firstLoaded, setFirstLoaded] = useState(true); // Add this line

  const handleDateChange = (event) => {
    setDates({...dates, [event.target.id]: event.target.value});
  };

  const handleFileChange = (event) => { // New function to update the selected file
    setSelectedFile(event.target.value);
  };

  const handleSwitchChange = () => {
  // Perform any additional logic here if needed
  setOptimize(optimize === "true" ? "false" : "true");
};

  useEffect(() => {
    document.title = "Matt Marinelli | RLTrader";
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  useEffect(() => {
    fetch('https://portfolio-backend-sux6.onrender.com/get_files')
      .then(response => response.json())
      .then(data => {
        const filesWithoutExtension = data.map(file => file.replace(/\.csv$/, ''));
        const sortedFiles = filesWithoutExtension.sort();
        const optionElements = sortedFiles.map((file, index) => <option key={index} value={file}>{file}</option>);
        setOptions(optionElements);
      });
  }, []);


const validateDates = () => {
  const dateValues = Object.values(dates).map(date => new Date(date));
  for (let i = 0; i < dateValues.length - 1; i++) {
    if (dateValues[i] >= dateValues[i + 1]) {
      setError("Each date should be earlier than the one after it.");
      return;
    }
  }
  setError("");
  setIsLoading(true);
  setFirstLoaded(false);

  // Send a POST request to the API with the dates and the selected file
  fetch('https://portfolio-backend-sux6.onrender.com/run_experiment', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      train_sd: dates.date1,
      train_ed: dates.date2,
      test_sd: dates.date3,
      test_ed: dates.date4,
      symbol: selectedFile,
      optimize: optimize
    })
  })
  .then(response => response.json())
  .then(data => {
    setIsLoading(false);
    if (data.success) {
      // Fetch the in-sample image
      fetch('https://portfolio-backend-sux6.onrender.com/get_in_sample_png')
        .then(response => response.blob())
        .then(blob => {
          const url = URL.createObjectURL(blob);
          setInSampleImageUrl(url);
        });

      // Fetch the out-sample image
      fetch('https://portfolio-backend-sux6.onrender.com/get_out_sample_png')
        .then(response => response.blob())
        .then(blob => {
          const url = URL.createObjectURL(blob);
          setOutSampleImageUrl(url);
        });

      // Fetch the in-sample CSV
      fetch('https://portfolio-backend-sux6.onrender.com/get_in_sample_csv')
        .then(response => response.text())
        .then(text => {
          const data = Papa.parse(text, {header: true}).data;
          setInSampleCsvData(data);
        });

      // Fetch the out-sample CSV
      fetch('https://portfolio-backend-sux6.onrender.com/get_out_sample_csv')
        .then(response => response.text())
        .then(text => {
          const data = Papa.parse(text, {header: true}).data;
          setOutSampleCsvData(data);
        });
    } else {
      setInSampleImageUrl(null); // Reset to null
        setOutSampleImageUrl(null); // Reset to null
    }
  })
  .catch(error => {
      setIsLoading(false);
    console.error(error)
  });
};

  useEffect(() => {
    document.title = "RL+Optimization Trader";
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  return (
      <div>
          <div className="header-image">
              <img src="/lines.png"
                   alt="Asset by Oziel Gómez"/>
          </div>
          <div className="items-container">
              <div className="align-items-center">
                  <h1>Summary</h1>
                  <p className="blurb">
                      Using Model Free Reinforcement Learning, a Q-table is trained on technical indicators (specifically
                      Bollinger percent, relative strength index, stochastic oscillator, and triple exponential moving average)
                      generated from the training period price data. These values are discretized into five values that
                      are used to define the current state in the Q-table. The Q-table receives reward based on the change
                      in stock price from day to day and the action it took the previous day (go long 1000 shares, short 1000
                      shares, or hold no shares). Using optimization, the
                      model can refine the bounds used for discretizaton to more tightly tailor the model to the selected stock
                      at the cost of processing time and risk of overfitting. When trying out the code below, please be patient
                      as the backend server used only has 512MB of RAM, so it may take a few minutes for the model to converge.
                  </p>
              </div>
              <h1>Try It!</h1>
              <TextField
                  id="date1"
                  label="Training Start Date:"
                  type="date"
                  value={dates.date1}
                  min="2002-01-01"
                  max="2011-12-31"
                  InputLabelProps={{
                      shrink: true,
                  }}
                  className="date-input"
                  onChange={handleDateChange} // Add this line
              />
              <TextField
                  id="date2"
                  label="Training End Date:"
                  type="date"
                  value={dates.date2}
                  min="2002-01-01"
                  max="2011-12-31"
                  InputLabelProps={{
                      shrink: true,
                  }}
                  className="date-input"
                  onChange={handleDateChange} // Add this line
              />
              <TextField
                  id="date3"
                  label="Testing Start Date:"
                  type="date"
                  value={dates.date3}
                  min="2002-01-01"
                  max="2011-12-31"
                  InputLabelProps={{
                      shrink: true,
                  }}
                  className="date-input"
                  onChange={handleDateChange} // Add this line
              />
              <TextField
                  id="date4"
                  label="Testing End Date:"
                  type="date"
                  value={dates.date4}
                  min="2002-01-01"
                  max="2011-12-31"
                  InputLabelProps={{
                      shrink: true,
                  }}
                  className="date-input"
                  onChange={handleDateChange} // Add this line
              />
              <p className="Symbol">Stock Symbol:</p>
              <FileDropdown options={options}
                            handleFileChange={handleFileChange}/> {/* Pass the options and handleFileChange as props */}
              <p className="Symbol">Optimize:</p>
              <CustomSwitch
                  checked={optimize === "true"}
                  onChange={handleSwitchChange}
              />
              <button className="submit-button" onClick={validateDates}>Submit</button>
              {error && <p>{error}</p>}
          </div>

          {isLoading ? <BeatLoader color={"#ffffff"} className="loading-spinner" /> : null}

          {!isLoading && inSampleImageUrl && <img className="in-sample-image" src={inSampleImageUrl} alt="In-sample image"/>}
          {!isLoading && outSampleImageUrl && <img className="out-sample-image" src={outSampleImageUrl} alt="Result"/>}
          {!isLoading &&
          <div className={`scrollable-table-in ${!isLoading && !firstLoaded ? 'loaded': ''}`}>
          <table>
              <thead>
              <tr>
                  {inSampleCsvData[0] && Object.keys(inSampleCsvData[0]).map((key, index) => <th
                      key={index}>{key}</th>)}
              </tr>
              </thead>
              <tbody>
              {inSampleCsvData.map((row, index) => (
                  <tr key={index}>
                      {Object.values(row).map((value, index) => <td key={index}>{value}</td>)}
                  </tr>
              ))}
              </tbody>
          </table>


        </div>
          }
          {!isLoading &&
          <div className={`scrollable-table-out ${!isLoading && !firstLoaded ? 'loaded': ''}`}>
          <table>
              <thead>
              <tr>
                  {outSampleCsvData[0] && Object.keys(outSampleCsvData[0]).map((key, index) => <th
                      key={index}>{key}</th>)}
              </tr>
              </thead>
              <tbody>
              {outSampleCsvData.map((row, index) => (
                  <tr key={index}>
                      {Object.values(row).map((value, index) => <td key={index}>{value}</td>)}
                  </tr>
              ))}
              </tbody>
          </table>
              </div>
          }

          <br className="buffer"/>

      </div>
  );
}

export default RLTrader;
